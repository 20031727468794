import { lazy } from "react";
import { Route } from "react-router-dom";

const HomePage = lazy(() =>
  import("../pages/HomePage").then((module) => ({
    default: module.default,
  }))
);

const SparkAdminPage = lazy(() =>
  import("../spark-admin/App").then((module) => ({
    default: module.default,
  }))
);

export const URL_PARAMS = {
  SPARK_ADMIN: "spark-admin",
};

export const ROOT_ROUTERS = {
  HomePage: <Route key='HomePage' path={`/`} element={<HomePage />} />,
  SparkAdminPage: (
    <Route
      key='SparkAdminPage'
      path={`/${URL_PARAMS.SPARK_ADMIN}/*`}
      element={<SparkAdminPage />}
    />
  ),
};
