import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import headerReducer from "./headerSlice"
const store = configureStore({
  reducer: {
    user: userReducer,
    header: headerReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
