import KeppleMainLogo from "../../assets/images/public/KeppleMainLogo.png";
import styles from "./index.module.scss";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import globalStyles from "../../global/_globalStyles.module.scss";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";

interface IHeaderButton {
  text: string;
  link: string | null;
  selected: boolean;
}

const scrollSectionIntoView = (sectionId: string | null) => {
  if (sectionId) {
    scroller.scrollTo(sectionId, {
      duration: 500,
      smooth: true,
      offset: 50, // Scrolls to element + 50 pixels down the page
    });
  }
};

const HeaderButton = ({ text, link, selected }: IHeaderButton) => (
  <div
    className={styles.headerButton}
    onClick={() => {
      scrollSectionIntoView(link);
    }}
  >
    <div className={styles.background}></div>
    <a className={`${styles.text} ${styles.headerButtonLink}`}>{text}</a>
  </div>
);

const HEADER_BUTTONS_CONFIG = [
  { text: "HOME", link: "intro" },
  { text: "ABOUT", link: "services" },
  { text: "PROJECTS", link: "projects" },
  { text: "OPPORTUNITIES", link: "opportunities" },
  { text: "CONTACT", link: "contactus" },
];

const Header = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.onscroll = function setDropdownUnvisible() {
      setOpenDropdown(false);
    };
  }, []);

  return (
    <div
      className={styles.header}
      style={{
        display: location.pathname.includes("spark-admin") ? "none" : "",
      }}
    >
      <div className={styles.container}>
        <img
          className={styles.keppleLogo}
          src={KeppleMainLogo}
          alt='KeppleMainLogo'
        />
        <div className={styles.buttonsContainer}>
          {HEADER_BUTTONS_CONFIG.map((config, index) => (
            <HeaderButton
              key={config.text}
              text={config.text}
              link={config.link}
              selected={false}
            />
          ))}
        </div>
      </div>
      <Box
        className={styles.dropdownIconWrapper}
        onClick={() => setOpenDropdown((preState) => !preState)}
      >
        <MenuIcon
          className={styles.dropdownIcon}
          sx={{
            color: openDropdown
              ? globalStyles.purpleKepple
              : globalStyles.blackKepple,
          }}
        />
      </Box>
      <Popper
        open={openDropdown}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
        className={styles.headerMenulist}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              sx={{
                backgroundColor: globalStyles.whiteKepple,
                boxShadow: "none",
              }}
            >
              <MenuList aria-labelledby='composition-button'>
                {HEADER_BUTTONS_CONFIG.map((config) => (
                  <MenuItem
                    className={styles.dropDownLi}
                    onClick={() => {
                      scrollSectionIntoView(config.link);
                    }}
                  >
                    <HeaderButton
                      text={config.text}
                      link={config.link}
                      selected={false}
                    />
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Header;
