import React from "react";

import ErrorShownPic from "../../assets/images/ErrorBoundaries/errorImage.png";
import Stack from "@mui/material/Stack";
import "./index.module.scss";
import Box from "@mui/material/Box";

type ErrorBoundariesProps = { children: JSX.Element | JSX.Element[] };

type ErrorBoundariesState = {
  hasError: boolean;
};

export default class ErrorBoundaries extends React.Component<
  ErrorBoundariesProps,
  ErrorBoundariesState
> {
  constructor(props: ErrorBoundariesProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: any) {
    console.log("The errors that catched by error boundary:");
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      //TODO Add a custom image
      return (
        <Stack
          id='errorfallback__stack'
          alignItems='center'
          sx={{ width: "100%" }}
        >
          <img
            src={ErrorShownPic}
            alt='web site broke down img'
            id='errorfallback__ErrorShownPic'
          />
          <Box fontFamily='Lexend Deca' id='errorfallback__fallbackInfo'>
            Sorry, website is down temporarily. Looking forward to see you back
            soon.
          </Box>
        </Stack>
      );
    }
    return this.props.children;
  }
}
