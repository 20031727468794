import KeppleMainLogo from "../../assets/images/public/KeppleMainLogo.png";
import styles from "./index.module.scss";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AppleImg from "../../assets/images/public/Apple.png";
import GooglePlayImg from "../../assets/images/public/GooglePlay.png";
import KeppleSquareLogoImg from "../../assets/images/public/KeppleSquareLogo.png";
import { useLocation } from "react-router";

const Footer = () => {
  const location = useLocation();

  return (
    <div
      className={styles.footer}
      style={{
        display: location.pathname.includes("spark-admin") ? "none" : "",
      }}
    >
      <div className={styles.contentContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.leftUpperContainer}>
            <div>
              <img
                className={styles.keppleLogo}
                src={KeppleMainLogo}
                alt='footer-kepple-Logo'
              />
            </div>
            <div className={styles.paragraph}>
              Transforming today’s cutting-edge ideas into tomorrow’s reality.
            </div>
          </div>
          <div className={styles.socialMediaLinksContainer}>
            <div
              onClick={() =>
                window.open("https://au.linkedin.com/company/kepple1")
              }
            >
              <LinkedInIcon
                className={`${styles.socialMediaIcon} ${styles.linkedInIcon}`}
              />
            </div>
            <img
              src={GooglePlayImg}
              alt='GooglePlayImg'
              className={`${styles.socialMediaIcon} ${styles.googlePlayIcon}`}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/developer?id=Kepple"
                )
              }
            />
            <img
              src={AppleImg}
              alt='AppleImg'
              onClick={() =>
                window.open(
                  "https://apps.apple.com/au/developer/andrew-sheehan/id1607855195"
                )
              }
              className={`${styles.socialMediaIcon} ${styles.appleIcon}`}
            />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <img
            src={KeppleSquareLogoImg}
            alt='KeppleSquareLogoImg'
            className={styles.squaredKepple}
          />
          <div className={styles.rightBottomContainer}>
            <div>(C) Kepple</div>
            <div>ABN 47 654 729 932</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
