import Box from "@mui/material/Box";
import { Oval } from "react-loader-spinner";
import styles from "./index.module.scss";
import globalStyles from "../../global/_globalStyles.module.scss";

const LoadingSpinner = () => (
  <Box
    className={styles.page}
    sx={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Oval
      height={80}
      width={80}
      color={globalStyles.darkerWhiteKepple}
      wrapperStyle={{}}
      wrapperClass=''
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor={globalStyles.blackKepple}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </Box>
);

export default LoadingSpinner;
